.hero {
    display: flex;
    justify-content: space-between;
}

.hero-blur {
    width: 22rem;
    height: 30rem;
    left: 0px;
}

.left-h {
    flex: 2.5;
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    padding-bottom: 40px;
}

.right-h {
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.the-best-ad {
    margin-bottom: 40px;
    margin-top: 80px;
    background-color: #363d42;
    width: fit-content;
    border-radius: 30px;
    padding: 20px 13px;
    color: white;
    text-transform: uppercase;
    position: relative;
    /* i use flex because z index property does not work for children but when i use postion relative for other children i dont have to use flex */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: flex-start; */
}

.the-best-ad>span {
    position: relative;
    /* highest value of z-index
     will show on top.
     According to google
     An element with a higher z-index value will appear in front of an element with a lower z-index value */
    z-index: 2;
}

.the-best-ad>div {
    position: absolute;
    background-color: var(--orange);
    width: 30%;
    height: 80%;
    left: 8px;
    top: 6px;
    border-radius: 30px;
    z-index: 1;
}

.hero-text {
    display: flex;
    flex-direction: column;
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    /* margin-left: 70px; */
    text-overflow: inherit;
}

.hero-text>div:nth-child(1) {
    margin-bottom: 20px;
}

.hero-text>div:nth-of-type(3) {
    font-size: 16px;
    color: white;
    letter-spacing: 1px;
    text-transform: none;
    width: 83%;
    margin-top: 14px;
}

.figures {
    display: flex;
    width: 50%;
    gap: 10px;
    /* margin-left: 70px; */
    margin-top: 20px;
}

.figures>div {
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1) {
    color: white;
    font-size: 30px;
}

.figures>div>span:nth-of-type(2) {
    color: rgb(175, 170, 170);
    text-transform: uppercase;
}

.hero-buttons {
    display: flex;
    margin-top: 30px;
    gap: 25px;
}

.hero-buttons>:nth-child(1) {
    cursor: pointer;
    padding: 10px 25px;
    background-color: var(--orange);
    color: white;
    border: none;
}

.hero-buttons>:nth-child(2) {
    cursor: pointer;
    padding: 10px 25px;
    background-color: transparent;
    border: 2px var(--orange) solid;
    color: white;
}

/* right-h start */

.right-h>.join-now-btn {
    position: absolute;
    top: 30px;
    right: 70px;
    cursor: pointer;
    padding: 10px 15px;
    border: none;
    background-color: white;
    font-weight: bold;
}

.heart-rate {
    display: flex;
    flex-direction: column;
    gap: 7px;
    background-color: var(--darkGrey);
    width: fit-content;
    position: absolute;
    top: 120px;
    right: 100px;
    padding: 15px 20px 20px 15px;
    align-items: start;
    border-radius: 5px;
}

.heart-img {
    height: 40px;
    width: 45px;
}

.heart-rate>:nth-child(2) {
    color: var(--gray);
}

.heart-rate>:nth-child(3) {
    color: white;
    font-size: 20px;
}

.hero-image {
    position: absolute;
    width: 300px;
    height: 400px;
    top: 150px;
    left: -140px;
    z-index: 2;
}

.hero-image-back {
    position: absolute;
    width: 220px;
    height: 340px;
    top: 60px;
    left: -200px;
    z-index: 1;
}

.calories {
    background-color: rgba(128, 128, 128, 0.932);
    display: flex;
    padding: 7px 15px;
    position: absolute;
    top: 500px;
    left: -360px;
    border-radius: 5px;
    width: fit-content;
}

.calories>img {
    width: 55px;
}

.calories-text {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
    padding-left: 27px;
}

.calories-text>span:nth-of-type(1) {
    color: rgb(207, 203, 203);
    font-size: 16px;
}

.calories>div:nth-child(2) {
    color: white;
    font-size: 20px;
}



@media screen and (max-width: 768px) {
    .hero {
        flex-direction: column;
    }

    .left-h {
        padding: 0;
    }

    .hero-blur {
        width: 14rem;
    }

    .the-best-ad {
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .hero-text {
        align-items: center;
        font-size: xx-large;
    }

    .hero-text>div:nth-of-type(3) {
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .hero-buttons {
        justify-content: center;
    }

    .figures {
        width: 100%;
        /* margin: auto; */
        justify-content: space-evenly;
        margin-left: 20px;
    }

    .figures>div>span:nth-of-type(1) {
        font-size: large;
    }

    .figures>div>span:nth-of-type(2) {
        font-size: small;
    }

    .right-h {
        position: relative;
        background: none;
    }

    .heart-rate {
        position: relative;
        top: 1rem;
        left: 2rem;
    }

    .calories {
        position: relative;
        top: 5rem;
        left: 2rem;
        margin: auto;
    }

    .calories>img {
        width: 2rem;
    }

    .calories>div>:nth-child(2) {
        font-size: 1rem;
    }

    .hero-image {
        position: relative;
        width: 15rem;
        left: 7rem;
        top: 4rem;
        align-self: center;
    }

    .hero-image-back {
        width: 10rem;
        height: 18rem;
        top: 17rem;
        transform: rotate(-10deg);
    }






}